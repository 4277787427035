<template>
  <div v-loading="allLoading">
    <el-container class="material-management">
      <el-header class="material-management-header">
        <div class="material-management-header-left">
          <el-button class="add-directory" type="primary" :disabled="addDisabled" @click="addDirectory">添加目录</el-button>
          <el-upload class="upload-demo" :multiple="true" action='' accept="image/jpeg,image/png,image/gif,video/mp4"
            :show-file-list="false" :http-request="handleUpload" :before-upload="beforeAvatarUpload"
            :on-success="(res, file) => handleAvatarSuccess(res, file, 'local')" :on-error="uploadError" :on-change="uploadChange">
            <el-button type="primary" :disabled="checkFile && checkFile.folder_id === rootFolderId">上传文件</el-button>
          </el-upload>
          <span v-if="isCheckbox">
            <el-button class="move-directory" type="primary" @click="moveDirectory">移动</el-button>
            <el-button class="delete-directory" type="primary" @click="batchDeletion">批量删除</el-button>
            <el-button class="cancel-select" type="primary" @click="cancelSelect">取消选择</el-button>
          </span>
        </div>
        <div class="material-management-header-right">
          <el-select v-model="list_params.storage" :disabled="storageType != ''" placeholder="文件类型"
            @change="changeStorage" clearable @clear="changeStorage">
            <el-option v-for="item in storageList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input v-model="list_params.material_name" placeholder="名称" @change="changeStorage" clearable
            @clear="changeStorage"></el-input>
        </div>
      </el-header>

      <el-container>
        <el-aside class="material-management-aside" :width="isCheckbox ? '350px' : '250px'">
          <div class="directory-title">
            目录：
            <div class="directory-title-text">{{ catalogue }}</div>
            <el-button v-if="catalogue" type="text" @click="handleCopy(catalogue)">复制</el-button>
          </div>
          <el-tree ref="tree" :data="menuData" :props="defaultProps" node-key="folder_id" default-expand-all
            :expand-on-click-node="false" @node-click="handleNodeClick">
            <div class="custom-tree-node" slot-scope="{ node, data }">
              <div class="custom-tree-node-item" :class="list_params.folder_id === data.folder_id ? 'tree-active' : ''">
                <span class="tree_active"><i class="el-icon-folder-opened"></i>{{ node.label }}</span>
                <span class="custom-tree-node-button tree_active" v-if="data.operational">
                  <el-button class="tree_active" type="text" size="mini" @click="moveDirectoryItem(node, data)">
                    移动
                  </el-button>
                  <el-button class="tree_active" type="text" size="mini" @click="editDirectory(node, data)">
                    编辑
                  </el-button>
                  <el-button class="tree_active" type="text" size="mini" @click="deleteDirectory(node, data)">
                    删除
                  </el-button>
                </span>
              </div>
            </div>
          </el-tree>
        </el-aside>
        <el-main class="material-management-main">
          <div class="img_pick" :class="isCheckbox ? '' : 'reduce'" v-loading="loading">
            <ul class="clearfix">
              <li class="img_item" @click.capture="selectImage(imageitem, index)"
                :class="selectImageList[imageitem.material_id] ? 'active' : ''" v-for="(imageitem, index) in imageList"
                :key="index">
                <div class="img_item_bd" v-if="imageitem.storage == 'video'">
                  <video class="video-html" @click.prevent="handleVideoClick" :src="imageitem.url"
                    controls="controls"></video>
                  <el-button class="copy-btn" type="text" @click.stop="copyPath(imageitem.url)">
                    复制路径
                  </el-button>
                  <div class="check_content">
                    <span class="img_name" :title="imageitem.name">
                      {{ imageitem.material_name }}
                    </span>
                  </div>
                </div>
                <div v-else class="img_item_bd">
                  <div class="pic" :style="{ backgroundImage: 'url(' + (wximageurl + imageitem.url) + ')' }">
                    <el-button class="copy-btn" type="text" @click.stop="copyPath(imageitem.url)">复制路径</el-button>
                  </div>
                  <div class="check_content"><span class="img_name" :title="imageitem.name">{{ imageitem.material_name
                      }}</span>
                  </div>
                </div>
              </li>
            </ul>
            <div class="pagination-box">
              <p>已选中{{ Object.keys(selectImageList).length }}个</p>
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="list_params.page" :page-sizes="[10, 20, 30, 40]" :page-size="list_params.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
              </el-pagination>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>

    <el-dialog :title="directory_info ? '编辑目录' : '添加目录'" :modal="isCheckbox" :visible.sync="directoryDialogVisible"
      width="30%" :before-close="addDirectory">
      <el-input v-model="directoryName" placeholder="请输入目录名称"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDirectory">取 消</el-button>
        <el-button type="primary" @click="addDirectorySave">保存</el-button>
      </span>
    </el-dialog>

    <el-dialog title="移动" :visible.sync="moveDialogVisible" width="30%" :before-close="closeMoveDialog">
      <div>
        <el-cascader v-model="cascaderValue" @change="handleChange" :options="cascaderOptions"
          :props="{ ...defaultProps, checkStrictly: true }" clearable></el-cascader>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeMoveDialog">取 消</el-button>
        <el-button type="primary" @click="moveSave">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getEspierMaterialFolder,
  getEspierMaterialList,
  getEspierMaterialSave,
  postEspierMaterialFolder,
  getEspierMaterialMovefolder,
  deleteEspierMaterialFolder,
  deleteEspierMaterial,
  getEspierMaterialMove
} from "@/api/wechat";
import UploadUtil from '@/utils/uploadUtil'
import { useDialog } from '@shopex/finder'

export default {
  props: {
    isCheckbox: { // 是否可以多选
      type: Boolean,
      default: false
    },
    storageType: { // 图片类型
      type: String,
      default: ''
    }
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'folder_name',
        company_id: 'company_id',
        folder_id: 'folder_id',
        parent_id: 'parent_id',
        sort: 'sort',
        operational: 'operational',
        value: 'folder_id',
        disabled: 'disabled'
      },
      menuData: [],
      directoryDialogVisible: false,
      directoryName: '',
      moveDialogVisible: false,
      moveRadio: '',
      shiftPressed: false, // 默认 Shift 键未被按下
      loading: false,
      imageList: [],
      checkFile: null,
      list_params: {
        folder_id: '',
        company_id: '',
        storage: '',
        material_name: '',
        page: 1,
        pageSize: 20
      },
      breadList: [],
      catalogue: '',
      directory_info: null,
      moveType: '', // image：图片移动，folder：文件夹移动
      selectImageList: {},
      total: 0,
      endIndex: '',
      startIndex: '',
      storageList: [
        {
          value: 'image',
          label: '图片'
        },
        {
          value: 'video',
          label: '视频'
        }
      ],
      addDisabled: false,
      cascaderValue: [],
      cascaderOptions: [],
      fileLength: 0,
      allLoading: false,
      count: 0,
      rootFolderId: null,
      errorList: []
    }
  },
  mounted() {
    this.list_params.storage = this.storageType
    this.espierMaterialFolder(true)
    // 在 mounted 生命周期钩子中全局监听
    window.addEventListener('keydown', this.handleKeydown);
    window.addEventListener('keyup', this.handleKeyup);
  },
  beforeDestroy() {
    // 组件销毁前移除事件监听器
    window.removeEventListener('keydown', this.handleKeydown);
    window.removeEventListener('keyup', this.handleKeyup);
  },
  methods: {
    // 添加目录弹窗
    addDirectory() {
      this.directoryDialogVisible = !this.directoryDialogVisible
      if (!this.directoryDialogVisible) {
        this.directory_info = null
        this.directoryName = ''
      }
    },
    // 自定义上传
    handleUpload: function (e) {
      let upload = null
      if (e.file.type == 'video/mp4') {
        upload = new UploadUtil('videos')
      } else {
        upload = new UploadUtil()
      }
      this.allLoading = true
      // 上传
      upload.uploadImg(e.file, e.file.name)
        .then((res) => {
          e.onSuccess(res)
          // this.changeLoading()
        }, err => {
          // this.changeLoading()
          e.onError(err)
        })
        .catch((err) =>e.onError(err))
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      // const isPNG = file.type === 'image/png';
      // const isGIF = file.type === 'image/gif';
      // const isLt2M = file.size / 1024 / 1024 < 10;

      // if (!isJPG && !isPNG && !isGIF) {
      //   this.$message.error('上传图片只能是 JPG 或者 PNG 格式!')
      //   return
      // }
      // if (!isLt2M) {
      //   this.$message.error('上传图片大小不能超过 10MB!')
      //   return
      // }
    },
    handleAvatarSuccess(res, file, upload_source) {
      if (!this.checkFile) {
        this.$message({
          message: '请先选择目录',
          type: 'warning'
        });
        return
      }
      let uploadParams = {
        company_id: this.checkFile.company_id,
        folder_id: this.checkFile.folder_id,
        material_name: file.name,
        storage: 'image',   //图片id必填
        material_url: res.key,
      }
      if (file.raw.type == 'video/mp4') {
        uploadParams.storage = 'video'
      }
      getEspierMaterialSave(uploadParams).then(res => {
        this.list_params.page = 1
        this.espierMaterialList()
        this.$message({
          message: '上传成功',
          type: 'success',
          duration: 5 * 1000
        })
        this.changeLoading()
      }).catch((err) => {
        this.errorList.push(err)
        this.changeLoading()
        this.$message.error(err);
      })
    },
    changeLoading() {
      this.count = this.count + 1
      if (this.count == this.fileLength || this.count > this.fileLength) {
        this.allLoading = false
        // this.count = 0
        // this.fileLength = 0
        if(this.errorList.length == 0) return
        useDialog(null, {
          title: `错误信息`,
          width: '500px',
          errorList: this.errorList,
          component: () => import('./errormsg.vue'),
          actions: [
            { label: '取消', key: 'close', size: 'default' }
          ]
        }).then(() => {
          this.errorList = []
        })
      }
    },
    uploadChange(file, fileList) {
      this.fileLength = fileList.length
    },
    // 上传错误回调
    uploadError: function (e) {
      console.error(e)
    },
    // 移动图片按钮
    moveDirectory() {
      this.moveType = 'image'
      this.moveVisible()
    },
    // 批量删除按钮
    batchDeletion() {
      if (!Object.keys(this.selectImageList).length) {
        this.$message({
          message: '请先选择图片',
          type: 'warning'
        });
        return
      }
      let params = {
        material_id: Object.keys(this.selectImageList).join(',')
      }
      deleteEspierMaterial(params).then(res => {
        this.$notify.success({
          message: '已删除',
          showClose: true
        })
        this.selectImageList = {}
        this.espierMaterialList()
      })
    },
    // 取消选择按钮
    cancelSelect() {
      this.selectImageList = {}
    },
    changeStorage() {
      this.list_params.page = 1
      this.espierMaterialList()
    },
    async handleCopy(value) {
      await this.$copyText(value)
      this.$notify.success({
        message: '链接复制成功',
        showClose: true
      })
    },
    // 选择文件夹，获取当前文件夹图片列表
    handleNodeClick(node, data) {
      this.list_params.company_id = node.company_id
      this.list_params.folder_id = node.folder_id
      this.checkFile = node
      this.selectImageList = {}
      this.list_params.page = 1
      this.espierMaterialList()
      // 获取当前文件的所有父级，形成目录
      this.breadList = []
      //  获取点击当节点的dom的信息
      let selectNode = this.$refs.tree.getNode(node)
      // 调用递归函数
      this.platform(selectNode)
      // 形成目录路径
      let catalogue = ''
      this.breadList.forEach((item, index) => {
        if (index != 0) {
          catalogue += '/' + item.folder_name
        }
      })
      if (this.breadList.length >= 4) {
        this.addDisabled = true
      } else {
        this.addDisabled = false
      }
      this.catalogue = catalogue

    },
    // 递归函数
    platform(node) {
      if (!node.parent) {
        return
      }
      this.breadList.unshift(node.data)
      //调用递归
      this.platform(node.parent)
    },
    // 文件夹移动
    moveDirectoryItem(node, data) {
      this.moveType = 'folder'
      this.directory_info = data
      this.moveVisible()
    },
    // 编辑目录
    editDirectory(node, data) {
      this.addDirectory()
      this.directoryName = data.folder_name
      this.directory_info = data
    },
    // 删除目录
    deleteDirectory(node, data) {
      this.$confirm('是否删除此目录', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteEspierMaterialFolder(data.folder_id).then(res => {
          this.catalogue = ''
          this.espierMaterialFolder(true)
        })
      })
    },
    // 添加目录/编辑目录
    addDirectorySave() {
      if (!this.directoryName) {
        this.$message({
          message: '请输入目录名称',
          type: 'warning'
        });
        return
      }
      if (!this.checkFile && !this.directory_info) {
        this.$message({
          message: '请先选择目录',
          type: 'warning'
        });
        return
      }
      let params = {
        folder_name: this.directoryName,
        parent_id: this.checkFile.folder_id,
        sort: this.checkFile.sort
      }
      if (this.directory_info) {
        params.folder_id = this.directory_info.folder_id
        params.sort = this.directory_info.sort
        params.folder_id = this.directory_info.folder_id
      }
      postEspierMaterialFolder(params).then(res => {
        this.addDirectory()
        this.espierMaterialFolder(false)
      })
    },
    // 获取素材文件夹列表
    espierMaterialFolder(type) {
      getEspierMaterialFolder().then(res => {
        const { data } = res.data
        this.menuData = data
        if (type) {
          this.list_params.company_id = data[0].company_id
          this.list_params.folder_id = data[0].folder_id
          this.checkFile = data[0]
          this.rootFolderId = data[0].folder_id
        }
        this.espierMaterialList()
      })
    },
    // 获取文件夹下图片列表
    espierMaterialList() {
      let params = {
        ...this.list_params
      }
      getEspierMaterialList(params).then(res => {
        this.imageList = res.data.data.list
        this.total = res.data.data.total_count
      })
    },
    // 移动保存
    moveSave() {
      if (this.moveType == 'image') {
        // 移动图片
        if (!Object.keys(this.selectImageList).length) {
          this.$message({
            message: '请先选择图片',
            type: 'warning'
          });
          return
        }
        if (!this.moveRadio) {
          this.$message({
            message: '请选择移动到哪个文件夹下',
            type: 'warning'
          });
          return
        }
        let params = {
          folder_id: this.moveRadio,
          material_id: Object.keys(this.selectImageList).join(',')
        }
        getEspierMaterialMove(params).then(res => {
          this.selectImageList = {}
          this.closeMoveDialog()
          this.espierMaterialList()
        })
      } else {
        // 移动文件夹
        if (!this.moveRadio) {
          this.$message({
            message: '请选择移动到哪个文件夹下',
            type: 'warning'
          });
          return
        }
        let params = {
          folder_id: this.directory_info.folder_id, // 当前文件夹id
          move_parent_folder_id: this.moveRadio // 移动到哪个文件夹下
        }
        getEspierMaterialMovefolder(params).then(res => {
          this.espierMaterialFolder(false)
          this.moveVisible()
        })
      }
    },
    moveVisible() {
      this.moveDialogVisible = !this.moveDialogVisible
      this.getTypeOptions()
      if (!this.moveDialogVisible) {
        this.moveType = ''
        this.directory_info = null
      }
    },
    closeMoveDialog() {
      this.moveDialogVisible = false
      this.moveRadio = ''
      this.cascaderValue = []
    },
    selectImage(item, index) {
      if (this.shiftPressed && this.isCheckbox) {
        // 按住shift键实现点到点多选
        let obg = {}
        if (this.startIndex === '') {
          this.startIndex = index
        } else {
          this.endIndex = index
        }
        let start = this.startIndex < this.endIndex ? this.startIndex : this.endIndex
        let end = this.startIndex < this.endIndex ? this.endIndex : this.startIndex
        if (this.startIndex !== '' && this.endIndex !== '') {
          for (let i = start; i <= end; i++) {
            obg[this.imageList[i].material_id] = this.imageList[i]
          }
        }
        this.selectImageList = obg
      } else if (this.isCheckbox) {
        // 单次选择一个，可多选
        let obj = { ...this.selectImageList }
        if (obj[item.material_id]) {
          delete obj[item.material_id]
        } else {
          obj[item.material_id] = item
        }
        this.selectImageList = obj
        // 循环obj组成数组
        let arr = []
        for (let i in obj) {
          arr.push(obj[i])
        }
        this.$emit('selectImage', arr)
      } else {
        // 单次选择一个，不可多选
        if (this.selectImageList[item.material_id]) {
          delete this.selectImageList[item.material_id]
        } else {
          this.selectImageList = { [item.material_id]: item }
        }
        this.$emit('selectImage', this.selectImageList[item.material_id])
      }
    },
    handleSizeChange(val) {
      this.list_params.pageSize = val
      this.espierMaterialList()
    },
    handleCurrentChange(val) {
      this.list_params.page = val
      this.espierMaterialList()
    },
    handleVideoClick(event) {
      event.preventDefault();
    },
    copyPath(name) {
      this.$copyText(name)
      this.$notify.success({
        message: '链接复制成功',
        showClose: true
      })
    },
    handleKeydown(event) {
      // 监听 Shift 键是否按下
      if (event.shiftKey) {
        this.shiftPressed = true;
      }
    },
    handleKeyup(event) {
      // 监听 Shift 键是否释放
      if (!event.shiftKey) {
        this.shiftPressed = false;
        this.startIndex = ''
        this.endIndex = ''
      }
    },
    handleChange(v) {
      this.moveRadio = v[v.length - 1]
    },
    getTypeOptions() {
      let menuData = JSON.parse(JSON.stringify(this.menuData))
      if (this.moveType == 'image') {
        menuData[0].disabled = true
        this.cascaderOptions = menuData
      } else {
        const firstThreeLevels = this.getFirstThreeLevels(menuData);
        this.cascaderOptions = firstThreeLevels
      }
    },
    getFirstThreeLevels(nodes, currentLevel = 0, maxLevel = 3) {
      if (currentLevel >= maxLevel) {
        return [];
      }
      return nodes.map(node => {
        const resultNode = { ...node }; // 克隆当前节点，避免修改原始数据
        if (resultNode && resultNode.folder_id == this.directory_info.folder_id) {
          resultNode.disabled = true // 已选中的目录不可选
        }
        if (node.children && node.children.length > 0) {
          resultNode.children = this.getFirstThreeLevels(node.children, currentLevel + 1, maxLevel); // 递归获取子节点
          // 已选中的目录的子目录不可选
          if (resultNode.folder_id == this.directory_info.folder_id) {
            resultNode.children.forEach(item => {
              item.disabled = true
            })
          }
        }
        return resultNode;
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.material-management {
  border: 1px solid #ccc;
}

.material-management-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  height: 80px !important;
  line-height: 80px !important;

  .material-management-header-left,
  .material-management-header-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.upload-demo {
  margin-right: 11px;
}

.material-management-aside {
  border-right: 1px solid #ccc;
}

.material-management-main {}

.custom-tree-node {
  width: 100%;
  // height: 24px;

  .custom-tree-node-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .custom-tree-node-button {
    display: none;

    .el-button--mini {
      padding: 0;
    }
  }

  &:hover {
    .custom-tree-node-button {
      display: block;
    }
  }
}

.tree-active {
  background: #409EFF;

  .tree_active {
    color: #fff;
  }
}

.move-radio-group {
  margin: 10px 0;
}

.move-radio-group-item {
  margin-right: 10px;

  .el-icon-arrow-right {
    margin-left: 10px
  }
}

.directory-title {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  padding: 0 10px;

  .directory-title-text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.material-management-main {
  .img_pick {
    margin-right: -1%;
    max-height: 700px;
    overflow-y: auto;
    padding: 10px 0 0 10px;

    .img_item {
      margin-bottom: 20px;
      margin-right: 1%;
      border: 2px solid #fff;
      width: 226px;
    }

    .active {
      border: 2px solid #409EFF;
    }

    .img_item_bd {
      position: relative;
    }

    .pic {
      width: 222px;
      height: 222px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-bottom: 1px solid #e7e7eb;
      position: relative;
    }

    .video-html {
      vertical-align: middle;
      width: 222px;
      height: 221px;
    }

    .check_content {
      width: 100%;
      padding: 0 5px;
    }

    .img_name {
      text-align: left;
      height: 34px;
      line-height: 34px;
      overflow: hidden;
      display: inline-block;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .reduce {
      height: 463px;

      .img_item {
        width: 148px;
      }

      .pic,
      .video-html {
        width: 144px;
        height: 144px;
      }
    }
}

.img_pick {
  margin-right: -45px;

  .img_item {
    float: left;
    text-align: center;
    border: 1px solid #fff;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);

    .copy-btn {
      display: none;
      border-radius: 0;
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;
      background: rgba($color: #eae9e9, $alpha: 0.8);
      color: #409EFF;
      padding: 5px 0;
      font-size: 12px;
    }

    &:hover {
      .copy-btn {
        display: block;
      }
    }
  }
}

.msg_card {
  background-color: #f4f5f9;
  border-top: 1px solid #e7e7eb;
  width: 100%;

  .opr_item {
    height: 34px;
    line-height: 34px;
    cursor: pointer;
    color: #999;
    font-size: 16px;

    &:hover {
      color: #333;
    }
  }
}

.pagination-box {
  display: flex;
  justify-content: space-between;
}

.edit-directory {
  z-index: 2100;
}
</style>
